var moment = require('moment');

export function EventsManager() {
	let events = [],
	    eventIds = [];

	function reset() {
		events = [];
		eventIds = [];
	}

	// function receives the event array, and moment start and end object
	function addEventToArray(event, start, end) {
		let singleEvent = {};

		singleEvent.all_day = "no";
		singleEvent.id = event.id;
		singleEvent.location = event.location;
		singleEvent.page_content = event.page_content;
		singleEvent.sd = start.format('MMMM Do YYYY, h:mm:ss a');
		singleEvent.start_date = start.valueOf();
		singleEvent.summary = event.summary;
		singleEvent.title = event.title;
		singleEvent.topics = event.topics;
		singleEvent.type = event.type;
		singleEvent.url = event.url;

		//https://stackoverflow.com/questions/12462318/find-a-value-in-an-array-of-objects-in-javascript
		let recurrenceCounter = eventIds[eventIds.findIndex(x => x.id == event.id)];

		if(recurrenceCounter === undefined) {
			eventIds.push({
				id: singleEvent.id,
				count: 1
			});
		} else {
			let recurrenceCount = recurrenceCounter["count"] + 1;
			eventIds[eventIds.findIndex(x => x.id == event.id)].count = recurrenceCount;
			singleEvent.id = singleEvent.id + "_" + recurrenceCount;
		}

		events.push(singleEvent);
	}

	function processRecurringEvent(event) {
		let daysMap = {Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6};
		let interval = event.interval;

		// make "allDay" a boolean
		event.allDay = event.allDay === "true";

		//FRANK - Changed these from moment.utc to moment
		let mStart = moment(event.startISO);
		let mEnd = moment(event.endISO);
		let mUntil = moment(event.untilISO);
		let mDiff = mEnd.diff(mStart, "s");
		let _mStart = mStart.clone();

		//---------------------BEGIN FRANK DEBUG----------------------------/
		if (event.id == "8ee6096996fa4bca19984a4e72f05e5b")
		{
			console.log("------------------FRANK EVENT------------------");
			console.log(event);
			console.log("mStart: " + mStart);
			console.log("event.startISO: " + event.startISO);
			console.log("mEnd: " + mEnd);
			console.log("mUntil: " + mUntil);
			console.log("event.untilISO: " + event.untilISO);

			console.log("mDiff: " + mDiff);
			console.log("_mStart: " + _mStart);
		}
		//---------------------END FRANK DEBUG-----------------------------/


		// If no until date was provided, default to 2 years out from the starting date.
		if (event.untilISO === undefined) {
		  	mUntil = mStart.clone().add(2, "y");
		} else {
		  	// Cap the until date to 2 years out from the current year for repeating events.
		  	if (mUntil.diff(mStart, "y") > 2) {
		    	mUntil.years(mStart.years()+2);
		  	}
		}

		switch (event.frequency) {
		  case 'Daily':
		    // Add an occurrence for each day+interval.
		    while (mUntil.diff(mStart, "s") >= 0) {
		      addEventToArray(event, mStart, mEnd);

		      // Increment the start date based chosen interval.
		      mStart.add(interval, "d");
		      mEnd.add(interval, "d");
		    }
		    break;
		  case 'Weekly':
			  let days = event.days.split(",");

				//FRANK - Change this from < 1 to = 1?
		    // Are we adding a single weekly occurrence, or multiple?
		    if (days.length = 1) {
					// console.log("ONE WEEKLY OCCURANCE");
					// console.log("Diff start to end date: " + mUntil.diff(mStart, "s"));

					let frankcount = 0;
					while (mUntil.diff(mStart, "s") >= 0) {
		        addEventToArray(event, mStart, mEnd);

						frankcount = frankcount + 1;

						console.log(frankcount + " mStart: " + mStart);
						console.log(frankcount + " mEnd: " + mEnd);

		        mStart.add(interval, "w");
		        mEnd.add(interval, "w");

						console.log(frankcount + " New mStart: " + mStart);
						console.log(frankcount + " New mEnd: " + mEnd);
						console.log(frankcount + "EVENT UNTIL/START DIFF: " + mUntil.diff(mStart, "s") );


		      }
		    } else {
		      while (mUntil.diff(mStart, "s") >= 0) {
		        // Loop through each chosen day and add the occurrences.

				  for (var daysIterator = 0; daysIterator < days.length; daysIterator++) {
					  var day = days[daysIterator];

			          mStart.date(mStart.date()+(daysMap[day]-mStart.day()));
			          mEnd = mStart.clone().add(mDiff, "s");

			          // Only add occurrences that are after the actual start date.
			          if (mStart.diff(_mStart, "s") >= 0) {
			            addEventToArray(event, mStart, mEnd);
			          }

				  }

		        // Increment the start date based chosen interval.
		        mStart.add(interval, "w");
		        mEnd.add(interval, "w");
		      }
		    }
		    break;
		  case 'Monthly':
		    let dayOfMonthOption = event.monthDay;

			if (dayOfMonthOption == 'day of the month') {
		      // Add a monthly occurrence based on the original event's date.
		      while (mUntil.diff(mStart, "s") >= 0) {
		        // Ensure the date exists within the current month.
		        if (mStart.date() == _mStart.date()) {
		          addEventToArray(event, mStart, mEnd);
		        }

		        // Increment the start date based chosen interval.
		        mStart.add(interval, "M");
		        if (mStart.daysInMonth() >= _mStart.date()) {
		          mStart.date(_mStart.date());
		        }
		        mEnd = mStart.clone().add(mDiff, "s");
		      }
		    } else {
		      // Add a monthly occurrence based on the original event's day of the week.
		      // Determine the position of this day in the month (ie 1st, 2nd ...)
		      var theWeek = Math.floor(mStart.date()/7),
		          theDay = mStart.day()+1,
		          tempMonth = mStart.month();
		      while (mUntil.diff(mStart, "s") >= 0) {
		        // If we didn't roll over into the next month, add the event.
		        if (tempMonth === mStart.month()) {
		          addEventToArray(event, mStart, mEnd);
		        }

		        // Figure out where the next month's first day lies and add the difference
		        // in days with the weekly position.
		        mStart.add(interval, "M").date(1);
		        tempMonth = mStart.month();
		        if (theDay <= mStart.day()) {
		          mStart.date((theDay-mStart.day()+((theWeek+1)*7)));
		        } else {
		          mStart.date((theDay-mStart.day()+(theWeek*7)));
		        }

		        mEnd = mStart.clone().add(mDiff, "s");
		      }
		    }

		    break;
		  case 'Yearly':
		    // Add an occurrence for each day+interval.
		    while (mUntil.diff("s", mStart) >= 0) {
		      addEventToArray(event, mStart, mEnd);

		      // Increment the start year based chosen interval.
		      mStart.add(interval, "y");
		      mEnd.add(interval, "y");
		    }
		    break;
		}
	}

	function processEventsSet(eventsArray) {
		for(let i = 0; i < eventsArray.length; i++) {
			processRecurringEvent(eventsArray[i]);
		}
		return events;
	}

	return {
		reset,
		processEventsSet
	}

} // EventsManager
