import { FormatObj } from './Format.js';
import { SearchForm } from './SearchForm.js';

let Format = new FormatObj();
let searchF = new SearchForm();

export function GetObj() {
	var $ = jQuery;

    // the field ids are from the form input
    var news_field_ids = ['filter', 'date-to', 'date-from', 'topics'],
        event_field_ids = ['filter', 'date-to', 'date-from', 'topics', 'event-type'];

    function get_month_name(month) {
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months[month];
  }

  function get_event_time(date) {
      var d = new Date(parseInt(date));
      var options = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
      };
      return d.toLocaleString('en-US', options).toLowerCase();
  }

  function get_result_row_wrap_class(i, results_length) {
      var row_num = i + 1;
      var odd_even = (row_num % 2 === 0) ? "even" : "odd";
      var wrapper_class = `views-row views-row-${row_num} views-row-${odd_even}`;

      if (i === 0) {
          wrapper_class = `${wrapper_class} views-row-first`;
      }

      if (row_num === results_length) {
          wrapper_class = `${wrapper_class} views-row-last`;
      }

      wrapper_class = `${wrapper_class} clearfix`;

      return wrapper_class;
  }

  function get_result_image_div(image_url, image_alt, article_url) {
      return $("<div>", {
          class: "views-field views-field-field-news-photo"
      }).append(
          $("<div>", {
              class: "field-content"
          }).append(
              $("<a>", {
                  href: article_url
              }).append(
                  $("<img>", {
                      typeof: "foaf:Image",
                      src: image_url,
                      //width: 848,
                      //height: 477,
                      alt: image_alt
                  })
              )
          )
      );
  }

  function get_result_title_div(title, article_url) {
      return $("<div>", {
          class: "views-field views-field-title"
      }).append(
          $("<span>", {
              class: "field-content"
          }).append(
              $("<a>", {
                  href: article_url.replace(/amp;/g, "")
              }).html(title)
          )
      );
  }

  function get_result_date_div(date) {
      return $("<div>", {
          class: "views-field views-field-field-news-article-date"
      }).append(
          $("<div>", {
              class: "field-content"
          }).append(
              $("<span>", {
                  class: "date-display-single",
                  property: "dc:date",
                  datatype: "xsd:dateTime",
                  content: "2018-06-07T12:00:00-04:00"
              }).text(Format.format_start_date(date))
          )
      );
  }

  function get_result_event_heading(text) {
      return $("<div>", {
          class: "views-field event-heading-details"
      }).append(
          $("<div>", {
              class: "field-content"
          }).text(text)
      );
  }

  function get_result_summary_div(summary) {
      return $("<div>", {
          class: "views-field views-field-body"
      }).append(
          $("<div>", {
              class: "field-content"
          }).html(summary)
      );
  }

  function get_protocol_host() {
      return `${window.location.protocol}//${window.location.host}`;
  }

  function get_redirect_url(path) {
	  EVT.emit("redirectToUrl", "testing");
      return `${get_protocol_host()}${path}`;
  }

  function get_filter_summary_link(field_id, text, mode) {
      text = text.replace('&amp;', '&');
      return $("<a>", {
          text: text.replace(/\+/g, ' '),
          href: "#",
          click: function() {
              var encoded = encodeURIComponent(text);

              // redirect to the correct landing page if the current page is not one
              if (mode === "news" && !searchF.is_landing_page(searchF.news_landing_pathname)) {
                  window.location = get_redirect_url(searchF.news_landing_pathname) + `?${field_id}=${encoded}`;
              } else if ( mode === "itn" && !searchF.is_landing_page(searchF.itn_landing_pathname) ) {
                  window.location = get_redirect_url(searchF.itn_landing_pathname) + `?${field_id}=${encoded}`;
              } else if (mode === "events" && !searchF.is_landing_page(searchF.events_landing_pathname)) {
                  window.location = get_redirect_url(searchF.events_landing_pathname) + `?${field_id}=${encoded}`;
              } else {
                searchF.update_form_values({
                    [field_id]: text
                });
				EVT.emit("filterData", `${field_id}=${encoded}`, mode);
              }

          }
      });
  }

  function get_news_field_ids() {
      return news_field_ids;
  }

  function get_event_field_ids() {
      return event_field_ids;
  }


  if(window.EVT) {
      EVT.on("getRedirectUrl", get_redirect_url);
  }


  return {
    get_month_name: get_month_name,
    get_event_time: get_event_time,
    get_result_row_wrap_class: get_result_row_wrap_class,
    get_result_image_div: get_result_image_div,
    get_result_title_div: get_result_title_div,
    get_result_date_div: get_result_date_div,
    get_result_event_heading: get_result_event_heading,
    get_result_summary_div: get_result_summary_div,
    get_protocol_host: get_protocol_host,
    get_redirect_url: get_redirect_url,
    get_filter_summary_link: get_filter_summary_link,
    get_news_field_ids: get_news_field_ids,
    get_event_field_ids: get_event_field_ids
  };
} // Get
