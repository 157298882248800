import { FormatObj } from './Format.js';

let Format = new FormatObj();

export function FeedWidget() {
	let $ = jQuery;

	// function displayFeedResults(results, target) {
	// 	var list = process_results(results);
	// 	target.html(list.children());
	// }

  	// initialize any feed widgets
	// there is another function call process ajax data
	// in Rowan Today
	//function process_ajax_data(data, element) {

  	function displayResults(data, element) {
		var wrap = $("<div>");

      	var today = new Date();
      	today.setHours(0);
      	today.setMinutes(0);
      	today.setSeconds(0);
      	today.setMilliseconds(0);

	  	// add a check for future events if needed.
		// otherwise news won't show and that's not good.

			//       	data = data.filter(function(i) {
			// console.log(i.start_date);
			//          	var start_date = new Date(i.start_date);
			//          return (start_date >= today);
			//        });

      	if(data.length === 0) {
          	element.html("<p>No upcoming events.</p>");
      	}

      	$.each(data, function(i, j) {
            let assetLink = j.url.replace(/\[d\]/g, "-");


          var result = $("<div>", {
              class: "views-row"
          });

          result.append(
              $("<div>", {
                  class: "views-field views-field-field-event-date rowan-event-date"
              }).append(
                  $("<div>", {
                      class: "field-content"
                  }).append(
                      $("<span>", {
                          class: "date-display-single"
                      }).text(Format.format_start_date(j.start_date))
                  )
              )
          );

          result.append(
              $("<div>", {
                  class: "views-field views-field-title"
              }).append(
                  $("<span>", {
                      class: "field-content"
                  }).append(
                      $("<a>", {
                          href: assetLink
                      }).html(j.title)
                  )
              )
          );

          wrap.append(result);

      });

      element.append(wrap.children());
  }

    function init() {
        $("[data-feed-widget]").each(function() {
        var feed_url = $(this).attr('data-feed-url').replace(/amp;/g, ""),
		  	limit = $(this).attr('data-feed-limit'),
		  	mode = $(this).attr('data-feed-mode'),
            _this = $(this);

        let eventsFilterBy = $(this).attr('data-filter-event'),
            eventsFilterValues = $(this).attr('data-filter-values');

		let feed_urls = [];

        // for events we will start with the current year and go back 1 year
		if(mode === "events") {
		    let year = new Date().getFullYear();

			feed_urls.push(`/events/${year}/index.json`);
			feed_urls.push(`/events/${year}/index-r.json`);

			for(let i = 1; i < 2; i++) {
				year = year-i;
			}
		} else {
			feed_urls.push(feed_url);
		}

		// emit event to RowanToday
		EVT.emit("initFeed", {
			  feed_url: feed_urls,
			  mode: mode,
			  target: _this,
        eventsFilterBy: eventsFilterBy,
        eventsFilterValues: eventsFilterValues,
			  limit: limit,
		});

    	// $.ajax(feed_url, { dataType: "json" })
//           		.then(function(data) {
// 					EVT.emit("initFeed", data, { target: _this, mode: events });
//             		//process_ajax_data(data, _this);
//           	});
    });
  }

  if(window.EVT) {
      EVT.on("init", init);
	    EVT.on("displayFeedWidgetResults", displayResults);
  }

} // FeedWidget
