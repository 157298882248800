import { GetObj } from './Get.js';

let Get = new GetObj();

export function FormatObj() {

  function format_start_date(date) {
      var d = new Date(parseInt(date));
      return Get.get_month_name(d.getMonth()) + " " + d.getDate() + ", " + d.getFullYear();
  }

  function format_start_date_from_string(string) {
      var d = new Date(string);
      return format_start_date_tmp(d);
  }

  function format_start_date_tmp(d) {
      return Get.get_month_name(d.getMonth()) + " " + d.getDate() + ", " + d.getFullYear();
  }

  return {
    format_start_date: format_start_date,
    format_start_date_from_string: format_start_date_from_string,
    format_start_date_tmp: format_start_date_tmp
  };

}; // Format
