import { GetObj } from './Get.js';
import { FormatObj } from './Format.js';
import { EventsManager } from './EventsManager.js';
import Fuse from 'fuse.js';

let Get = new GetObj();
let Format = new FormatObj();
let eventsManager = new EventsManager();

export function RowanToday() {

    var $ = jQuery,
	    results_per_page = 10,
        initial_shown = false,
        $pagination = $("#pagination"),
        args,
        mode,
	    target,
	    limit,
        feed_url,
        rss_feeds,
        news_articles,
        current_future_events,
        year_filter,
        events_home,
        eventsFilterBy,
        eventsFilterValues,
        no_results_message = 'No results found.';
        var ajax_data = [];

        function add_leading_zero(number) {
            if(number < 10) {
                return "0" + number;
            }
            return number;
        }

     function getFeedContent(feed) {
         return new Promise(function(resolve) {
			 //resolve
             $.ajax(feed, { dataType: "json" })
             	.then(resolve)
				.fail(resolve);
         });
     }

     function getRssFeedContent(feed) {
         return new Promise(function(resolve) {
             $.ajax(feed, {
                     accepts:{
                         xml:"application/rss+xml"
                     },
                     dataType:"xml",
             })
             .then(resolve);
         });
     }

     function addFeedContentToArray(data) {

         if($(data).find("item").size()) {

             var rss_feed_data = [];

             $(data).find("item").each(function () { // or "item" or whatever suits your feed
                var el = $(this);
                var pub_date = new Date(el.find("pubDate").text());
                var sort_date = pub_date.getFullYear() + "" + add_leading_zero(pub_date.getMonth()+1) + "" + add_leading_zero(pub_date.getDate());
                var start_date = pub_date.getTime();

                Array.prototype.push.apply(rss_feed_data, [{
                      title: el.find("title").text(),
                      url: el.find("link").text(),
                      sort_date: sort_date,
                      start_date: start_date,
                      summary: el.find("description").text()
                }]);
            });

             Array.prototype.push.apply(ajax_data, rss_feed_data);
         } else {
			 let firstResult = data[0];

             // check for recurrence
			 if(firstResult !== undefined && firstResult.recurrence) {
				 // get recurring events
				 data = eventsManager.processEventsSet(data);
				 eventsManager.reset();
			 }

			 Array.prototype.push.apply(ajax_data, data);
         }

         // if(ajax_data.length >= results_per_page && initial_shown === false) {
//              process_ajax_data(ajax_data);
//              initial_shown = true;
//              $("#loading").hide();
//          }
     }

    function init() {
        if(arguments.length === 0) {
            return false;
        }

      	 // check for filters
        if (arguments.length > 0) {
            args        = arguments[0];
          	feed_url    = args.feed_url ? args.feed_url : "";
          	mode        = args.mode ? args.mode : "";
			limit 		= args.limit ? args.limit : "10";
			target 		= args.target ? args.target : "";
          	year_filter = args.year ? args.year : "";
          	events_home = args.events_home ? args.events_home : "";
          	rss_feeds   = args.rss_feeds ? args.rss_feeds : "";
            eventsFilterBy = args.eventsFilterBy ? args.eventsFilterBy : "";
            eventsFilterValues = args.eventsFilterValues ? args.eventsFilterValues : "";
      	}

		if(target === "") {
            // show loading indicator
            $("#results-container > div.view").append(
                $("<div>", {id: "loading", class:"loading" }).text("Loading")
            );
	    }

        // originally feed url was one big feed, then it was discovered
        // once there were enough events we couldn't use one large index block.
        // Single feeds are currently used for topics and could be changed
        if (feed_url !== undefined && feed_url !== "" && feed_url instanceof Array === false) {
            feed_url = feed_url.replace(/amp;/g, "");
            feed_url = [feed_url];
        }

	    if(feed_url !== undefined) {
	       feed_url = feed_url.map(getFeedContent);

	       if(rss_feeds) {
	           rss_feeds = rss_feeds.map(getRssFeedContent);
	           Array.prototype.push.apply(feed_url, rss_feeds);
	       }

	       feed_url.reduce(
	              function(chain, filePromise) {
	                  return chain.then(function() {
	                          return filePromise;
	                      })
	                      .then(addFeedContentToArray)
						  // do nothing on catch
						  .catch(() => {});
	              },
	              Promise.resolve()
	          )
	          .then(function() {
	              process_ajax_data(ajax_data);
	          })
			  .catch(function() {
				  //console.log("Catch error here");
			  });
	   }

    }

	// Compare dates to sort
	function compareMilli(a,b) {
		if(a.start_date < b.start_date) return -1;
		if(a.start_date > b.start_date) return 1;
		return 0;
	}

    function process_ajax_data(data) {
      var param_values = {};
          news_articles = data;

          // sort the data by start date in case it come in out of order
          if(mode === "events") {
              news_articles.sort(compareMilli);
          } else {
            news_articles.sort(function(a, b) {
                return b.start_date - a.start_date;
            });
          }

        // filter by year if required
        if (year_filter) {
            news_articles = filter_helper(news_articles, "date-to", `${year_filter}/12/31`);
            news_articles = filter_helper(news_articles, "date-from", `${year_filter}/1/1`);
        }

        // remove any previous events that might have occured
        // since the last publish of the events JSON
        if (mode === "events" && (events_home === true || target !== "")) {
            if(eventsFilterBy) {
                news_articles = filter_news({
                    categories: eventsFilterValues
                });
            }

            current_future_events = $.grep(news_articles, function(n, i) {
                var today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                today.setMilliseconds(0);
                return filter_by_date(n.start_date, today, "from");
            });
        }

        // if there are variables in the url then filter and display
        // otherwise display the results now
        var arr = window.location.href.split('?');

		if (arr.length > 1 && arr[1] !== '' && arr[0] !== 'https://cascade.rowan.edu/render/page.act') {
            $.each(deparam(arr[1]), function(i, j) {
                param_values[i] = j;
            });

			EVT.emit("updateFormValues", param_values);

		    //SearchForm.update_form_values(param_values);
            filter_data(arr[1]);
		    // if target is not empty than it's a feed widget
        } else if(target !== "") {
        	if(mode === "events") {
				    EVT.emit("displayFeedWidgetResults", current_future_events.slice(0, limit), target);
        		//displayFeedResults(current_future_events.slice(0, limit), target);
        	} else {
				EVT.emit("displayFeedWidgetResults", news_articles.slice(0, limit), target);
        		//displayFeedResults(news_articles.slice(0, limit), target);
        	}
        } else {
			// original regular mode
            if (mode === "events" && events_home === true) {
                display_news_results(current_future_events);
            } else {
                display_news_results(news_articles);
            }
        }
    }

    function filter_data(serialized_form_data) {

        var data = deparam(serialized_form_data),
            field_ids = mode === "events" ? Get.get_event_field_ids() : Get.get_news_field_ids(),
            display_summary = false;
            // console.log(data);
        $.each(field_ids, function(i, j) {
            // console.log(`Yay: ${i} : ${j} : ` + data[j]);
            // data[j] = ESAPI.encoder().encodeForHTML(data[j]);
            // Frank Fix for filtering out XSS
            // Using https://github.com/YahooArchive/xss-filters
            var xssFilters = require('xss-filters');
            // data[j] = xssFilters.inHTMLData(data[j]);
            // console.log(`Yay: ${i} : ${j} : ` + data[j]);

            if (data[j]) {
                display_summary = true;
                //filter out XSS
                data[j] = xssFilters.inHTMLData(data[j]);
            } else {
                $(`#${j}`).val("");
            }

            $("#topics").trigger("chosen:updated");

              if(display_summary) {
                display_filter_summary(data);
              } else {
                $("#filter-summary").hide();
              }
        });

        if(display_summary === false) {
            data = "reset";
        }
        console.log(data)
        display_news_results(filter_news(data));
    }

    // deparam the query string
    // via https://stackoverflow.com/questions/6992585/jquery-deserialize-form
    function deparam(query) {
        var pairs, i, keyValuePair, key, value, map = {};
        // remove leading question mark if its there
        if (query.slice(0, 1) === '?') {
            query = query.slice(1);
        }
        if (query !== '') {
            pairs = query.split('&');
            for (i = 0; i < pairs.length; i += 1) {
                keyValuePair = pairs[i].split('=');
                key = decodeURIComponent(keyValuePair[0]);
                value = (keyValuePair.length > 1) ? decodeURIComponent(keyValuePair[1]) : undefined;
                map[key] = value;
            }
        }
        return map;
    }

    /*
     *
     * JSON data has & replaced with "&amp;" where the linking in the form
     *    and the listing does not, so convert "&" to "&amp;" to match the
     *    JSON
     */
    function filter_by_string(haystack, needle) {
        needle = needle.replace("&", "&amp;");
        return haystack ? haystack.toLowerCase().indexOf(needle.toLowerCase()) >= 0 : false;
    }

    function filter_by_date(date, value, to_from) {
        date = new Date(parseInt(date));
        var compare = typeof value.getMonth === 'function' ? value : new Date(value);

        if (to_from === "to") {
            compare.setHours(23);
            compare.setMinutes(59);
            compare.setSeconds(59);
            return date.getTime() <= compare.getTime();
        } else {
            return date.getTime() >= compare.getTime();
        }
    }

    // via https://stackoverflow.com/questions/2218999/remove-duplicates-from-an-array-of-objects-in-javascript
    // actually from http://www.tjcafferkey.me/remove-duplicates-from-array-of-objects/
    function remove_duplicate_results(arr, key) {
        if (!(arr instanceof Array) || key && typeof key !== 'string') {
            return false;
        }

        if (key && typeof key === 'string') {
            return arr.filter((obj, index, arr) => {
                return arr.map(mapObj => mapObj[key]).indexOf(obj[key]) === index;
            });

        } else {
            return arr.filter(function(item, index, arr) {
                return arr.indexOf(item) == index;
            });
        }
    }

    function filter_helper(articles, field, value) {
        var filtered_articles = articles;

        if (field === "filter") {
                var options = {
                  shouldSort: false,
                  threshold: 0.4,        // was 0.7
                  location: 0,
                  distance: 10000,
                  maxPatternLength: 32,  // was 32
                  minMatchCharLength: 3, // was 3
                  keys: [
                    {
                    	name: "title",
						weight: 0.7
                    },
            //         {
            //         	name: "summary",
						// weight: 0.3
            //         },
					{
						name: "page_content",
						weight: 0.3
					}
                 ]
                };

                var fuse = new Fuse(filtered_articles, options); // "list" is the item array
                var filtered_articles = fuse.search(value);
        }

        if (field === "date-from") {
            filtered_articles = $.grep(filtered_articles, function(n, i) {
                return filter_by_date(n.start_date, value, "from");
            });
        }

        if (field === "date-to") {
            filtered_articles = $.grep(filtered_articles, function(n, i) {
                return filter_by_date(n.start_date, value, "to");
            });
        }

        if (field === "topics") {
            filtered_articles = $.grep(filtered_articles, function(n, i) {
				if(n.topics) {
					return filter_by_topics(n.topics, value);
				}
                return false;
            });
        }

        if (field === "categories") {
            filtered_articles = $.grep(filtered_articles, function(n, i) {
                if(n.categories) {
                    return filter_by_categories(n.categories, value);
                }
                return false;
            });
        }

        if (field === "event-type") {
            filtered_articles = $.grep(filtered_articles, function(n, i) {
                return filter_by_string(n.type, value.replace(/\+/g, ' '));
            });
        }
        return filtered_articles;
    }

    function filter_by_topics(topics, value) {
        var found = false;
        $.each(value.split(','), function(i, j) {
            j = j.indexOf('&') !== -1 ? j.replace('&', '&amp;') : j;

            if (topics.indexOf(j) !== -1) {
                found = true;
                return false;
            }
        });
        return found;
    }

    function filter_by_categories(categories, value) {
        var found = false;
        $.each(value.split(';'), function(i, j) {
            j = j.indexOf('&') !== -1 ? j.replace('&', '&amp;') : j;

            if (categories.indexOf(j) !== -1) {
                found = true;
                return false;
            }
        });
        return found;
    }

    function filter_news(data) {
        var articles = news_articles;

        if(events_home === true && !(data["date-from"] || data["date-to"])) {
            articles = current_future_events;
        }

        if(data !== "reset") {
            $.each(data, function(i, j) {
                if (j) {
                    articles = filter_helper(articles, i, j);
                }
            });
        }

        return articles;
    }

    function process_results(results) {
        var result_wrap = $("<div>");

        $.each(results, function(i, e) {
            var event_time;

            var assetLink = e.url.replace(/\[d\]/g, "-");

            var row_wrap = $("<div>", {
                class: Get.get_result_row_wrap_class(i, results.length)
            });

            if (e.image) {
                // Harriet Fix for dashes in images - Frank 2021-04-19
                e.image =  e.image.replace(/\[d\]/g, "-");
                var test = Get.get_result_image_div(e.image, e.image_alt, assetLink);
                row_wrap.append(test);
            }

            //---------------------BEGIN FRANK DEBUG----------------------------/
            if (e.id == "8ee6096996fa4bca19984a4e72f05e5b")
            {

              console.log(e);
              // console.log(get_result_date_div(e.start_date));
              console.log("FRANKSTARTdate:");
              console.log(e.start_date);

              // console.log("mStart: " + mStart);
              // console.log("mEnd: " + mEnd);
              // console.log("mUntil: " + mUntil);
              // console.log("mDiff: " + mDiff);
              // console.log("_mStart: " + _mStart);
            }
            //---------------------END FRANK DEBUG-----------------------------/

            row_wrap.append(Get.get_result_title_div(e.title, assetLink));
            row_wrap.append(Get.get_result_date_div(e.start_date));



            // if there is a location value it's an event
            if (e.location) {
                row_wrap.append(Get.get_result_event_heading(e.location));
            }

            // if there is an all day value then it's an event
            if (e.all_day) {
                if (e.all_day === "yes") {
                    event_time = "(All day)";
                } else {
                    event_time = Get.get_event_time(e.start_date);
                }

                row_wrap.append(Get.get_result_event_heading(event_time));
            }

            row_wrap.append(Get.get_result_summary_div(e.summary));

            // attach tags for events
            if (e.topics || e.type) {
                var tmp = $("<div>").append("Tags: ");

                if (e.topics) {
                    tmp.append(summary_helper_topics(e.topics));

                    if (e.type) {
                        tmp.append(", ");
                    }
                }

                if (e.type) {
                    tmp.append(Get.get_filter_summary_link('event-type', e.type, mode));
                }

                row_wrap.append(tmp);
            }

            result_wrap.append(row_wrap);
        });

        return result_wrap;
    }

    function summary_helper_topics(topics) {
        var output = $("<div>");
        topics = topics.split(',');

        $.each(topics, function(i, topic) {
            output.append(
                Get.get_filter_summary_link('topics', topic, mode)
            );

            if (i !== topics.length - 1) {
                output.append($("<span>").text(", "));
            }
        });

        return output.children();
    }

    function display_filter_summary(data) {
        var results = $("<div>");

        if (data.filter) {
            var filter = data.filter;
            filter = filter.replace(/\+/g, ' ');
            results.append(
                $("<div>").html(`Filter: "${filter}"`)
            );
        }

        // handle dates first
        if (data['date-from'] && data['date-to']) {
            if (data['date-from'] === data['date-to']) {
                results.append(
                    $("<div>").html(`On ${Format.format_start_date_from_string(data['date-from'])}`)
                );
            } else {
                results.append(
                    $("<div>").html(`From ${Format.format_start_date_from_string(data['date-from'])} to ${Format.format_start_date_from_string(data['date-to'])}`)
                );
            }
        } else if (data['date-from']) {
            results.append(
                $("<div>").html(`From ${Format.format_start_date_from_string(data['date-from'])}`)
            );
        } else if (data['date-to']) {
            results.append(
                $("<div>").html(`Through ${Format.format_start_date_from_string(data['date-to'])}`)
            );
        }

        if (data.topics) {
            results.append(
                $("<div>").append("Topics: ").append(summary_helper_topics(data.topics))
            );
        }

        if (data['event-type']) {
            results.append(
                $("<div>").append("Event type: ").append(Get.get_filter_summary_link('event-type', data['event-type'], mode))
            );
        }

        $("#filter-summary").html(results.children());

        if (!$("#filter-summary").is(":visible")) {
            $("#filter-summary").show();
        }
    }

    function display_news_results(results) {
        var data_count = Object.keys(results).length;
        var total_pages = Math.ceil(data_count / results_per_page);

        // destroy and hide existing pagination
        $pagination.twbsPagination('destroy');
        $pagination.empty();

        if (data_count > results_per_page) {
            $pagination.twbsPagination($.extend({}, {}, {
                startPage: 1,
                totalPages: total_pages,
                onPageClick: function(event, page) {

                    var start_index = Math.max(page - 1, 0) * results_per_page;
                    var end_index = start_index + results_per_page;
                    var results_to_display = results.slice(start_index, end_index);

                    var list = process_results(results_to_display);

                    $('#results').html(list.children());

                    $('html, body').animate({
                        scrollTop: ($("#header").offset().top - 25)
                    }, 200);
                }
            }));

        } else if (data_count > 0) {
            var list = process_results(results);
            $('#results').html(list.children());
        } else {
            $('#results').html(no_results_message);

        }
		$("#loading").hide();
        //$("#results-container").show();
        $pagination.show();
    }

	EVT.on("init", init);
	EVT.on("filterData", filter_data);
	EVT.on("initFeed", init); // FeedWidget fires this event

    return {
        init: init,
        filter_data: filter_data
    };

}; /* RowanToday */
