var EventEmitter2 = require('eventemitter2').EventEmitter2;

window.EVT = new EventEmitter2();

import { RowanToday } from './RowanToday.js';
import { FeedWidget } from './FeedWidget.js';
import { SearchForm } from './SearchForm.js';
//import { DataHandler } from './DataHandler.js';

let rt = new RowanToday();
let fw = new FeedWidget();
let sf = new SearchForm();
//let dh = new DataHandler();

EVT.on("redirectToUrl", () => {
	console.log('test evt');
});
