import { GetObj } from "./Get.js";

const Get = new GetObj();

export function SearchForm() {

  var $ = jQuery,
	  news_landing_pathname = "/news/index.html",
      events_landing_pathname = "/events/index.html",
      itn_landing_pathname = "/in-the-news/index.html",
      show_advanced_search_text = "Show Advanced Options",
      hide_advanced_search_text = "Hide Advanced Options",
	  mode;

  function update_form_values(obj) {
      $.each(Object.keys(obj), function(i, j) {
          $(`#${j}`).val(obj[j]);
      });
  }

  function is_landing_page(landing_path) {
      var pathname = window.location.pathname;
      return pathname.indexOf('index.html') !== -1 ? (pathname === landing_path) : pathname === landing_path.replace("index.html", "");
  }

  function init(config) {
	  // if(config === undefined) {
// 		  return false;
// 	  }

		if(config === undefined) {
			mode = "news";
		} else {
			mode = config.mode;
		}

    	$( "#date-to" ).datepicker();
    	$( "#date-from" ).datepicker();

    	// add datepicker for events
    	if (mode === "events") {
        	$("#datepickerex").datepicker({
            	onSelect: function(date) {
                //var serialized = `date-from=${date}&date-to=${date}`;
				update_form_values({
                    "date-to": date,
                    "date-from": date
                });
                $("#filter-form").submit();
                //filter_data(serialized);
            }
        });
    }

    $("#advanced_search_toggle").text(show_advanced_search_text);
    $("#advanced_search_toggle").click(function() {
        $("#advanced_search_options").toggle();
        $(".chosen-select").chosen();
      if($(this).text() === show_advanced_search_text) {
        	$(this).text(hide_advanced_search_text);
      } else {
        $(this).text(show_advanced_search_text);
      }
    });

    // handle form submit
    $("#filter-form").on("submit", function(e) {
        e.preventDefault();

        var serialized = $(':not(select[name=topics])', this).serialize(),
            topic_vals = $('#topics').val();

        if (topic_vals) {
            serialized = serialized + "&topics=" + encodeURIComponent(topic_vals);
        }

        // redirect to the correct landing page if the current page is not one
        if (mode === "news" && !is_landing_page(news_landing_pathname)) {
            window.location = Get.get_redirect_url(news_landing_pathname) + "?" + serialized;
        } else if ( mode === "itn" && !is_landing_page(itn_landing_pathname) ) {
            window.location = Get.get_redirect_url(itn_landing_pathname) + "?" + serialized;
        } else if (mode === "events" && !is_landing_page(events_landing_pathname)) {
            window.location = Get.get_redirect_url(events_landing_pathname) + "?" + serialized;
        } else {
			      EVT.emit("filterData", serialized, mode); // RowanToday
        }
    });

    // handle form reset
    $("#filter-form").on("reset", function(e) {
        e.preventDefault();

        var field_ids = mode === "events" ? Get.get_event_field_ids() : Get.get_news_field_ids();

        $.each(field_ids, function(i, j) {
            $(`#${j}`).val("");
        });

        $("#filter-summary").hide();
        $("#topics").trigger("chosen:updated");
        $("#filter-form").submit();
    });
  }

  if(window.EVT) {
	  EVT.on("init", init);
	  EVT.emit("updateFormValues", update_form_values);
  }

  return {
    init: init,
    update_form_values: update_form_values,
    is_landing_page: is_landing_page,
    news_landing_pathname: news_landing_pathname,
    events_landing_pathname: events_landing_pathname,
    itn_landing_pathname: itn_landing_pathname
  };

};
